<template>
  <div>
    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>库存管理</el-breadcrumb-item>
      <el-breadcrumb-item>入库单</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <el-form  :model="addWarehouseenter" :rules="addWarehouseenterRules"
               ref="addWarehouseenterRef" label-width="100px">
        <el-row>
          <el-col>
            <el-form-item label="仓库" prop="warehouselist">
              <el-cascader
                  :options="TreeList" v-model="addWarehouseenter.warehouselist" class="width250"
                  :props="Treeprops" @change="getcheckList" placeholder="请选择仓库"
                  clearable></el-cascader>
            </el-form-item>
          </el-col>
          <el-col>
            <el-button @click="add" type="primary" :disabled="adddisabled">添加商品</el-button>
            <el-button @click="addPurchase" type="primary">选择采购订单</el-button>
            <el-button @click="addsalereturn" type="primary">选择销售退货订单</el-button>
          </el-col>
        </el-row>
        <el-form-item label-width="0">
          <el-table :data="addWarehouseenter.goodslist" border stripe  >
            <el-table-column type="index"></el-table-column>
            <el-table-column label="商品编码" prop="ga_code" width="100px"></el-table-column>
            <el-table-column label="商品名称" prop="g_name"></el-table-column>
            <el-table-column label="商品规格" width="300px">
              <template slot-scope="scope" >
                <div>{{scope.row.ga_name}}</div>
                <el-select v-model="scope.row.gad_id" placeholder="请选择日期" v-if="scope.row.goodsattrdate.length!=0"
                           clearable class="p_purchase_ids">
                  <el-option
                      v-for="item in scope.row.goodsattrdate"
                      :key="item.gad_id"
                      :label="`${item.gad_start_date}~${item.gad_end_date}`"
                      :value="item.gad_id">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
<!--            <el-table-column label="开始时间~结束时间" width="300px">-->
<!--              -->
<!--            </el-table-column>-->
            <el-table-column label="采购数量" prop="pi_number" v-if="is_p_num"></el-table-column>
            <el-table-column label="已入库数量" prop="pi_warhouse_number" v-if="is_p_wnum"></el-table-column>
            <el-table-column label="单位" prop="gu_name" width="50px"></el-table-column>
            <el-table-column label="入库数量" prop="prop" width="200px">
              <template slot-scope="scope">
                <el-input v-model="scope.row.wei_number" ></el-input>
              </template>
            </el-table-column>
<!--            <el-table-column label="删除">-->
<!--              <template slot-scope="scope">-->
<!--                <el-button type="danger" @click="delGoodsList(scope.row.ga_id)">删除</el-button>-->
<!--              </template>-->
<!--            </el-table-column>-->
          </el-table>
        </el-form-item>
        <el-form-item label="经办人：" prop="we_agent">
          <el-select v-model="addWarehouseenter.we_agent" placeholder="请选择"
                     clearable class="width250">
            <el-option
                v-for="item in userlist"
                :key="item.m_id"
                :label="item.m_name"
                :value="item.m_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="供应商：" prop="we_agent">
          <el-select v-model="addWarehouseenter.s_id" placeholder="请选择"
                     clearable class="width250" filterable>
            <el-option
                v-for="item in supplierList"
                :key="item.s_id"
                :label="item.s_company_name"
                :value="item.s_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="入库时间：" prop="we_date">
          <el-date-picker v-model="addWarehouseenter.we_date" class="width250"
                          type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="入库备注：" prop="p_remark">
          <el-input v-model="addWarehouseenter.we_remark" class="width250"></el-input>
        </el-form-item>

        <!--        提交-->
        <div style="float: right; padding-bottom:20px; ">
          <el-button @click="addgo">取消</el-button>

          <el-button type="primary" @click="addupPurchase" :loading="addloading">确定</el-button>
        </div>
      </el-form>

    </el-card>

    <el-dialog  title="选择商品" :visible.sync="addGoodsDialogVisible"
                width="70%">
      <el-row :gutter="30">
        <el-col :span="6">
          <el-input placeholder="请输入内容" v-model="querInfo.g_name" clearable @clear="getGoodsList">
            <el-button slot="append" icon="el-icon-search" @click="getGoodsList"></el-button>
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-cascader v-model="selectefKeys" :options="categorieslList" clearable placeholder="请选择商品分类"
                       :props="cascaderProps" @change="parentCateChanged"></el-cascader>
        </el-col>
      </el-row>
      <el-table :data="Goodslist"  stripe @select-all="changeFun" @select="asd" ref="docTable">

      <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="商品编码" prop="ga_code"></el-table-column>
        <el-table-column label="商品名称" prop="g_name"></el-table-column>
        <el-table-column label="商品规格" prop="ga_name">
          <template slot-scope="scope" >
            <div>{{scope.row.ga_name}}</div>
            <div v-if="scope.row.ga_start_date">{{scope.row.ga_start_date}}{{scope.row.ga_end_date}}</div>
          </template>
        </el-table-column>
        <el-table-column label="单位" prop="gu_name" width="120px"></el-table-column>
        <el-table-column label="质量等级" prop="ql_name" width="120px"></el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="querInfo.page"
          :page-sizes="[3,5,10,15]"
          :page-size="querInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addGoodsDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addGoodsDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="选择采购订单" :visible.sync="addPurchaseDialogVisible"
               width="70%" >
      <el-button @click="setCurrent">取 消 选 中</el-button>
      <el-table :data="purchaseList" ref="singleTable" stripe highlight-current-row   @current-change="handlePCurrentChange">

        <!--        <el-table-column type="selection" width="55"></el-table-column>-->
        <el-table-column type="index" width="50px"></el-table-column>
        <el-table-column label="采购单号" prop="p_code"></el-table-column>
        <el-table-column label="采购员" prop="p_purchase_name" width="80px"></el-table-column>
        <el-table-column label="供应商" prop="s_name" show-overflow-tooltip></el-table-column>
        <el-table-column label="采购时间" prop="p_date" show-overflow-tooltip></el-table-column>
        <el-table-column label="采购金额" prop="p_price" show-overflow-tooltip></el-table-column>
        <el-table-column label="采购总计" prop="p_total_price" show-overflow-tooltip></el-table-column>
        <el-table-column label="状态" prop="p_status_name" width="80px" show-overflow-tooltip></el-table-column>
        <el-table-column label="付款状态" prop="p_payment_status_name" width="80px"></el-table-column>

      </el-table>
      <el-pagination
          @size-change="PhandleSizeChange"
          @current-change="PhandleCurrentChange"
          :current-page="PquerInfo.page"
          :page-sizes="[3,5,10,15]"
          :page-size="PquerInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="Ptotal">
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addPurchaseDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addPwtergo()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="选择销售退货订单" :visible.sync="addsalereturnDialogVisible"
               width="70%" >
      <el-button @click="setCurrent">取 消 选 中</el-button>
      <el-table :data="salereturnlist" ref="singleTable" stripe highlight-current-row  @current-change="handlesaleChange">

        <!--        <el-table-column type="selection" width="55"></el-table-column>-->
        <el-table-column type="index" ></el-table-column>
        <el-table-column label="退款订单编号" prop="sr_code" width="200px"></el-table-column>
        <el-table-column label="销售订单编号" prop="sr_code" width="200px"></el-table-column>
        <el-table-column label="退货员" prop="sr_sale_name" width="80px" show-overflow-tooltip></el-table-column>
        <el-table-column label="退货时间" prop="sr_date" width="100px" show-overflow-tooltip></el-table-column>
        <el-table-column label="商品金额" prop="sr_price" width="100px" show-overflow-tooltip></el-table-column>
        <el-table-column label="其他金额" prop="sr_other_price" width="100px" show-overflow-tooltip></el-table-column>
        <el-table-column label="总金额"  width="100px" >
          <template slot-scope="scope">
            <div style="color: red;font-weight: bold;">{{scope.row.sr_total_price}}</div>
          </template>
        </el-table-column>
        <el-table-column label="添加人" width="90px" prop="add_uname" show-overflow-tooltip></el-table-column>
        <el-table-column label="付款状态" width="90px" prop="sr_payment_status_name" show-overflow-tooltip></el-table-column>
        <el-table-column label="是否作废">
          <template slot-scope="scope">
            <i class="el-icon-success" v-if="scope.row.sr_status==1" style="color: lightgreen"></i>
            <i class="el-icon-error" v-else style="color: red"></i>
          </template>
        </el-table-column>
        <el-table-column label="添加时间" width="150px" prop="add_date" show-overflow-tooltip></el-table-column>

      </el-table>
      <el-pagination
          @size-change="salehandleSizeChange"
          @current-change="salehandleCurrentChange"
          :current-page="salequerInfo.page"
          :page-sizes="[3,5,10,15]"
          :page-size="salequerInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="saletotal">
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addsalereturnDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addsalego()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      addloading:false,
      querInfo: {
        page: 1,
        limit: 10,
        ga_name:''
      },
      total:0,
      PquerInfo:{
        page: 1,
        limit: 10,
        p_warehouse_status:'1,2'
      },
      Ptotal:0,
      is_p_num:false,
      is_p_wnum:false,
      //添加表单的form表单
      addWarehouseenter:{
        we_type:2,
        warehouselist:[],
        goodslist:[],
        we_date:this.$store.state.daydate,
        we_agent:this.$store.state.m_id
      },
      Goodslist:[],
      //员工列表
      userlist:[],
      //供应商列表
      TreeList:[],
      //采购列表
      purchaseList:[],
      supplierList:[],
      Treeprops:{
        // multiple: true,
        // checkStrictly: true,
        expandTrigger: 'hover',
        // checkStrictly:true,
        value: 'w_id',
        label: 'w_name',
        children: 'children'},
      id:{'s_id':''},
      //控制添加商品的显示或隐藏
      addGoodsDialogVisible:false,
      addPurchaseDialogVisible:false,
      checkBoxData: [],
      //
      adddisabled:true,
      addWarehouseenterRules: {
        warehouselist: [
          {required: true, message: '请选择仓库', trigger: 'change'}
        ],
        we_agent:[
          {required: true, message: '请选择经办人', trigger: 'change'}
        ],
        we_date:[
          {required: true, message: '请选择入库时间', trigger: 'change'}
        ],
        // p_remark:[
        //   {required: true, message: '请输入入库备注', trigger: 'blur'}
        // ]
      },
      //商品分类list
      categorieslList:[],
      //选中的父级分类的ID数组
      selectefKeys: [],
      //指定级联选择器的配置对象
      cascaderProps: {
        expandTrigger: 'hover',
        // checkStrictly:true,
        value: 'c_id',
        label: 'c_name',
        children: 'children'
      },
      addsalereturnDialogVisible:false,
      salereturnlist:[],
      salequerInfo:{
        page: 1,
        limit: 10,
      },
      saletotal:0,
      salereturnid:''

    }
  },
  created() {
    this.showSetTreeListDialog()
    this.getuserList()
    this.getsupplierList()
    this.getcateList()
    this.getpurchaseList()
  },
  methods: {
    //获取采购订单
    async getpurchaseList() {
      const {data: res} = await this.$http.get('purchase/getList',
      {params:this.PquerInfo})
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.purchaseList = res.data.data
      this.Ptotal=res.data.total

    },
    //获取销售退货订单
    async getsalereturnList() {
      const {data: res} = await this.$http.get('salereturn/getList',{params:this.salequerInfo})
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.salereturnlist = res.data.data
      this.saletotal=res.data.total

    },
    //获取商品分类
    async getcateList() {
      const {data: res} = await this.$http.get('category/getMainList/3')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.categorieslList = res.data
    },
    //展示仓库的对话框
    async showSetTreeListDialog() {
      //获取所有权限的数据
      const {data: res} = await this.$http.get('warehouse/getTreeList')
      if (res.code != 200) return this.$message.error(res.msg)
      this.TreeList = res.data
    },
    //获取商品数据
    async getGoodsList(){
      const {data:res} = await this.$http.get('goods/getList',
          {params:this.querInfo})
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.Goodslist=res.data.data
      this.total=res.data.total
      setTimeout(() => {
        this.pickUp() //需要默认勾选的数据
      }, 10)
    },
    //获取供应商列表
    async getsupplierList() {
      const {data: res} = await this.$http.get('supplier/getMainList')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.supplierList = res.data
    },
    //获取采购员
    async getuserList() {
      const {data: res} = await this.$http.get('member/getMainList')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.userlist=res.data
    },
    //获取采购订单详情
    // async getPDli(id){
    //   if (id){
    //     const {data: res} = await this.$http.get('purchase/find/'+id)
    //     if (res.code !== 200) {
    //       return this.$message.error(res.msg)
    //     }
    //     this.addWarehouseenter.goodslist=res.data.goodslist
    //   }else{
    //     return false
    //   }
    //
    // },
    //监听pagesize改变
    handleSizeChange(newSize) {
      this.querInfo.limit = newSize
      this.getGoodsList(this.querInfo)
    },
    //监听pagenum 改变
    handleCurrentChange(newPage) {
      this.querInfo.page = newPage
      this.getGoodsList(this.querInfo)
    },
    //监听pagesize改变
    PhandleSizeChange(newSize) {
      this.PquerInfo.limit = newSize
      this.getpurchaseList(this.PquerInfo)
    },
    //监听pagenum 改变
    PhandleCurrentChange(newPage) {
      this.PquerInfo.page = newPage
      this.getpurchaseList(this.PquerInfo)
    },
    //监听pagesize改变
    salehandleSizeChange(newSize) {
      this.salequerInfo.limit = newSize
      this.getsalereturnList(this.PquerInfo)
    },
    //监听pagenum 改变
    salehandleCurrentChange(newPage) {
      this.salequerInfo.page = newPage
      this.getsalereturnList(this.PquerInfo)
    },
    //显示销售退货单的展示
    addsalereturn(){
      this.getsalereturnList()
      this.addsalereturnDialogVisible=true
    },
    //销售退货入库
    addsalego(){
      this.$router.push({path: "/goodssalereturn/goodssalereturnwarehouseenter", query: {editid: this.salereturnid}})
    },
    asd(selection, row) {
      // console.log(this.$refs.singleTable)
      // if (this.$refs.singleTable.isTrusted!=undefined){
      //   this.$refs.singleTable.setCurrentRow();
      // }
      let is_exists = true;
      // this.is_p_num=false
      // this.is_p_wnum=false
          //.setCurrentRow();
      this.addWarehouseenter.goodslist.forEach((item,index)=>{
        if (this.addWarehouseenter.goodslist[index].ga_id===row.ga_id) {
          is_exists = false;
          this.addWarehouseenter.goodslist.splice(index, 1)
        } else {
          // is_exists = false;
        }
      })
      if (is_exists) {
        this.addWarehouseenter.goodslist.push(row)
        is_exists = true;
      }
    },
    // 需要默认勾选的数据
    pickUp() {
      let arr = []
      this.Goodslist.forEach((item) => {
        this.addWarehouseenter.goodslist.forEach((val) => {
          if (val.ga_id === item.ga_id) {
            arr.push(item)
            console.log(arr,'123456')
            // this.radios.push(item.roleID)
          }
        })
      })
      this.toggleSelection(arr)
    },
    // checkboxT(row, rowIndex){
    //   this.Goodslist.forEach((item) => {
    //     this.addWarehouseenter.goodslist.forEach((val) => {
    //       if(val.ga_id === item.ga_id){
    //         return false;//禁用
    //       }else{
    //         return true;//不禁用
    //       }
    //     })
    //   })
    //
    //
    // },
    // 默认选择中的行
    toggleSelection(rows) {
      console.log(rows)
      if (rows) {
        rows.forEach((row) => {
          this.$refs.docTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.docTable.clearSelection()
      }
    },
    //供应商选项框发生变化时的回调函数
    getcheckList(){
      if (this.addWarehouseenter.warehouselist == []){
        console.log(this.addWarehouseenter.warehouselist,'等于空')
        this.addWarehouseenter.goodslist=[]
        this.adddisabled=true
        return true
      }else{
        console.log('有值')
        this.addWarehouseenter.w_id=this.addWarehouseenter.warehouselist[0]
        this.addWarehouseenter.wp_id=this.addWarehouseenter.warehouselist[1]
        // this.querInfo.w_id=this.addWarehouseenter.warehouselist[0]
        // this.querInfo.wp_id=this.addWarehouseenter.warehouselist[1]
        // this.id.s_id=this.ss_id
        this.addWarehouseenter.goodslist=[]
        this.getGoodsList(this.querInfo)
        this.adddisabled=false
      }
    },
    add(){
      this.addGoodsDialogVisible=true
    },
    addPurchase(){
      this.addPurchaseDialogVisible=true
    },
    //单选采购订单
    handlePCurrentChange(val) {
      // this.is_p_num=true
      // this.is_p_wnum=true
      // this.$refs.docTable.clearSelection()
      // this.addWarehouseenter.goodslist=[]
      this.purchaseid=val.p_id
      // this.getPDli(val.p_id)
      // this.selectPid=val.p_id
    },
    handlesaleChange(val){
      this.salereturnid=val.sr_id
      console.log(this.salereturnid)
    },
    setCurrent(row){
      this.is_p_num=false
      this.is_p_wnum=false
      this.addWarehouseenter.goodslist=[]
      this.$refs.singleTable.setCurrentRow(row);
    },
    handleSelectionChange (selection) {
      console.log(selection)
    },
    //提交form表单
    async addupPurchase(){
      this.$refs.addWarehouseenterRef.validate(async valid => {
        if (!valid) return
        this.addloading=true
        const {data: res} = await this.$http.post('warehouseenter/add', this.addWarehouseenter)
        this.addloading=false
        if (res.code !== 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.$router.go(-1)
      })
    },
    addgo(){
      this.$router.go(-1)
    },
    changeFun(selection) {
      let count = 0;
      this.Goodslist.forEach((items,indexs)=>{
        this.addWarehouseenter.goodslist.forEach((item,index)=>{
          if (items.ga_id === item.ga_id){
            this.addWarehouseenter.goodslist.splice(index, 1)
            count++;
          }
        })
      })
      if(count != this.querInfo.limit){
        this.Goodslist.forEach((item,index)=>{
          this.asd([],item)
        })
      }else{
        count = 0;
      }
    },
    delGoodsList(id){
      this.addWarehouseenter.goodslist.forEach((item,index)=>{
        if (item.ga_id===id){
          this.addWarehouseenter.goodslist.splice(index,1)
        }
        // this.addWarehouseenter.goodslist.splice(index,1)
      })
    },
    //商品分类
    parentCateChanged() {
      this.querInfo.g_cid=this.selectefKeys[this.selectefKeys.length-1]
      console.log(this.querInfo.g_cid)
      this.getGoodsList()
    },
    //跳转采购入库
    addPwtergo(){
      this.$router.push({path: "/PurchaseWarehousing", query: {editid: this.purchaseid}})
    }

  },


}
</script>
<style lang="less" scoped>

.width250{
  width: 250px!important;
}
</style>
